import React, { useEffect, useState } from 'react'
import { Router } from '@reach/router' // included with gatsby
// Components
import { Loading, Seo, ProductCard } from '../../components'
import RouterComponent from '../../components/RouterWrapper'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
import { useStore } from '../../context/StoreContext'
// Styles
import {
  ProductsGrid,
  ProductCategoryHeader,
  ProductCategoryTitle,
  ImageOverlay,
} from '../../styles/LandingPage.styles'
// Utils
import { Magento } from '../../services/magento'
import { filterProducts } from '../../utils/productHelpers'

const Type = ({ url_key, categoryData }) => {
  const [typeData, setType] = useState(categoryData)

  const { storeData, isLoadingStore } = useStore()

  useEffect(() => {
    if (!isLoadingStore) {
      const subCategories = storeData?.type?.subCategories
      setType(subCategories[`${url_key}`])
    }
  }, [isLoadingStore, url_key])

  return (
    <>
      <Seo title={typeData?.name ?? ''} />
      <ProductCategoryHeader backgroundImg={typeData?.image ?? null}>
        <ImageOverlay>
          <ProductCategoryTitle>{typeData?.name ?? ''}</ProductCategoryTitle>
        </ImageOverlay>
      </ProductCategoryHeader>
      <ProductsGrid>
        {typeData?.products?.map(product => (
          <ProductCard key={product.uid} {...product} />
        ))}
      </ProductsGrid>
    </>
  )
}

const TypePage = props => {
  const [categoryData, setProductData] = useState(null)

  const {
    prismicData: { prismicProductsPage },
  } = usePrismic()
  const { loading_products } = prismicProductsPage

  const { isEventSite, qUser, loading } = useAuthContext()
  const { isLoadingStore } = useStore()

  useEffect(() => {
    if (!isLoadingStore) return
    if (loading) return
    ;(async () =>
      await Magento.Store.getSingleCategoryData({
        url_key: props.params['*'],
      }).then(({ categories }) => {
        let category = categories.items[0]
        const categoryProducts = filterProducts(category.products.items, qUser)
        category.products = categoryProducts
        setProductData(category)
      }))()
  }, [loading])

  if (isLoadingStore && !categoryData)
    return (
      <Loading
        loading={true}
        message={loading_products[0].text}
        showBg={isEventSite}
      />
    )
  return (
    <>
      <Router primary={false} component={RouterComponent}>
        <Type path="/products/type/:url_key" categoryData={categoryData} />
      </Router>
    </>
  )
}

export default TypePage
